import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import fondoproyectos from "../archivos/imagenes/banner-bg.jpg";
import athenahp from "../archivos/imagenes/athenahp.jpg"
import minalovers from "../archivos/imagenes/minalovers.jpg";
import mpb from "../archivos/imagenes/mpbdist.jpg"
import athenastore from "../archivos/imagenes/athenastore.jpg";
import directimpresiones from "../archivos/imagenes/3direct.jpg";
import consultorio from "../archivos/imagenes/consultorio.jpg";
import taller from "../archivos/imagenes/Orazirepuestos.jpg";
import felix from "../archivos/imagenes/felixcasabe.jpg"
import tasty from "../archivos/imagenes/tastypizza.aea32d759a424b94b2f7.jpg"
import kustomk from "../archivos/imagenes/kustomkeyboard.jpg"
import camio from "../archivos/imagenes/camiov2.jpg"

export const Projects = () => {
  const projects = [
    {
      title: "3Direct Impresiones",
      description: "Hecha con ReactJS",
      imgUrl: directimpresiones,
      LinkUrl:"https://www.3directimpresiones.com.ar/"
    },
    {
      title: "Athena Halloween Party",
      description: "Hecha con ReactJS",
      imgUrl: athenahp,
      LinkUrl:"https://athenahalloweenparty.netlify.app/"
    },
    {
      title: "Athena Store",
      description: "Hecha con ReactJS",
      imgUrl: athenastore,
      LinkUrl:"https://www.athenastore.com.ar/"
    },
    {
      title: "Consultorio Todoroff",
      description: "Hecha con HTML y CSS",
      imgUrl: consultorio,
      LinkUrl:"https://www.silviatodoroff.com.ar/"
    },
    {
      title: "Felix Casabe",
      description: "Hecha con ReactJS",
      imgUrl: felix,
      LinkUrl:"https://www.felixcasabe.com.ar/"
    },
    {
      title: "Kustom Keyboards",
      description: "Hecha con ReactJS",
      imgUrl: kustomk,
      LinkUrl:"https://kustomkeyboards.netlify.app/"
    },
    {
      title: "Mina Lovers",
      description: "Hecha con HTML, CSS Y JS",
      imgUrl: minalovers,
      LinkUrl:"https://www.minalovers.com.ar/"
    },
    {
      title: "MPB Distribuidores",
      description: "Hecha con ReactJS",
      imgUrl: mpb,
      LinkUrl:"https://mpbdistribuidores.com.ar"
    },
    {
      title: "Orazi Repuestos",
      description: "Hecha con HTML Y CSS",
      imgUrl: taller,
      LinkUrl:"https://www.orazirepuestos.com.ar/"
    },
    {
      title: "Portfolio Camila Orazi",
      description: "Hecha con ReactJS",
      imgUrl: camio,
      LinkUrl:"https://www.camilaorazi.com.ar/"
    },
    //{
      //title: "Tasty Pizza",
      //description: "Hecha con ReactJS",
      //imgUrl: tasty,
      //LinkUrl:"https://www.tastypizza.com.ar/"
    //},
    
  ];

  return (
    <section className='project' id='proyectos'>
      <Container>
        <Row>
          <Col>
            
                
                  <h2>Proyectos</h2>
                  <p>
                   Aca podes ver algunos de mis proyectos personales!
                  </p>
                  <Tab.Container id='projects-tabs' defaultActiveKey='first'>
                    
                    <Tab.Content
                     
                    >
                      <Tab.Pane eventKey='first'>
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey='second'>
                        <p>
                          Todavia no llegue a programar tantas paginas, pero en un futuro cercano, voy a completar esta seccion tambien!
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey='third'>
                        <p>
                        Todavia no llegue a programar tantas paginas, pero en un futuro cercano, voy a completar esta seccion tambien!
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                
              
           
          </Col>
        </Row>
        
        
      </Container>
     
 
    </section>
  );
};
